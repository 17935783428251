const serviceAccount =  {
  apiKey: "AIzaSyD0JRof9p7Y7qDEFYFK0ZKLJcp27uKNfDU",
  authDomain: "ccnyacm-bb351.firebaseapp.com",
  databaseURL: "https://ccnyacm-bb351.firebaseio.com",
  projectId: "ccnyacm-bb351",
  storageBucket: "ccnyacm-bb351.appspot.com",
  messagingSenderId: "56360369364",
  appId: "1:56360369364:web:102f3e20bb40dfdeee4f41",
  measurementId: "G-DRGW96V7YV"
};

export { serviceAccount };
