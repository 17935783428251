import React from 'react';
import { Container, Toolbar, Typography } from '@material-ui/core';

export const CmsHome = () => {
 return (
    <Container>
      <Toolbar/>
      <Typography>
        CMS Home
      </Typography>
    </Container>
  );
 }
